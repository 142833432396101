import { render, staticRenderFns } from "./ModalFormula.vue?vue&type=template&id=ba4cc0e0"
import script from "./ModalFormula.vue?vue&type=script&lang=js"
export * from "./ModalFormula.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports